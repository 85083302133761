import server from '@/request';

const prefix = '/xiaozi-seller/wireless/notice';

// 发货列表(分页)
export const queryPage = (data = {}) => {
  return server.post(`${prefix}/queryPage`, data);
};

// 发货统计
export const queryStateInfo = (data = {}) => {
  return server.post(`${prefix}/queryStateInfo`, data);
};

// 取消发货
export const cancel = (data = {}) => {
  return server.post(`${prefix}/cancel`, data);
};

// 发货详情
export const details = (data = {}) => {
  return server.post(`${prefix}/details`, data);
};

// 修改发货详情
export const modifyDetails = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/notice/editAccompanyBillById', data);
};
